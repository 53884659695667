:root {
    --color-dark: #1f1c16;
    --color-darker: #080806;
    --color-text: white;
    --color-primary: #0201da;

    --color-background: var(--color-darker);
    --color-cards: var(--color-dark);
    --color-buttons: var(--color-primary);
}

#root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

a {
    text-decoration: none;
    color: inherit;
}

h2 {
    font-size: 1.5rem;
    font-weight: 500;
    color: #FFF9;
}

body {
    background-color: var(--color-background);
}
footer {
    background-color: var(--color-background);
    filter: drop-shadow(0 -3 0.75rem rgba(#F6F2C0, 0.1));
}

.btn-primary {
    background-color: var(--color-buttons) !important;
    border: 1px solid var(--color-buttons) !important;
}

.card {
    background-color: var(--color-cards) !important;
    color: var(--color-text);
}

.main-content {
    flex: 1;
}

.color-white {
    color: white;
}

.bg-dark {
    background-color: var(--color-dark) !important;
}

.border-dark {
    border-color: var(--color-darker) !important;
}

.cursor-pointer {
    cursor: pointer;
}

.mt-5 {
    margin-top: 3rem;
}

.zindex-min1 {
    z-index: -1;
}

.overscroll-y-none {
    overscroll-behavior-y: none !important;
}

.h-35rem {
    height: 35rem;
}

.mh-15rem {
    max-height: 15rem;
}
.mh-30rem {
    max-height: 30rem;
}

.filter-blur-8 {
    filter: blur(8px);
}

.drop-shadow {
    filter: drop-shadow(0 0 0.75rem rgba(0, 0, 0, 0.1));
}

.mnw-100 {
    min-width: 100%;
}
.width-100 {
    width: 100%;
}
.width-100vw {
    width: 100vw;
}
.mnw-100vw {
    min-width: 100vw;
}

.mnh-100 {
    min-height: 100%;
}

.modal-content {
    background-color: var(--color-cards);
    color: var(--color-text);
}

.liststyle-none {
    list-style: none;
}

.c-header__select {
    max-width: 8rem;
}

@media screen and (min-width: 384px) {
    .d-xs-flex {
        display: flex;
    }
    .justify-content-xs-center {
        justify-content: center;
    }
    .justify-content-xs-start {
        justify-content: flex-start;
    }

    .col-xs-6 {
        flex: 0 0 auto;
        max-width: 50%;
    }
}

@media screen and (min-width: 576px) {
    .mt-sm-6 {
        margin-top: 6rem !important;
    }
    .mh-sm-20rem {
        max-height: 20rem;
    }
}

@media screen and (min-width: 768px) {
    .text-md-left {
        text-align: left !important;
    }
}